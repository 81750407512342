import React from "react";

// Overview Views
// Dashboard Views
import Dashboard from "../views/overview/dashboard/dashboard-list-view";
// Challenge Suggestions Views
import ChallengeSuggestion from "../views/overview/challenge-suggestion/challenge-suggestion-list-view";
import CreateChallengeSuggestion from "../views/overview/challenge-suggestion/challenge-suggestion-create-view";
// Story Reports Views
import StoryReport from "../views/overview/story-report/story-report-list-view";
// Chat Views
import Chat from "../views/overview/chat/chat-view";
// ----------------------------------------------------------------------
// Staff Views
// Admin Views
import Admin from "../views/staff/admin/admin-list-view";
import CreateAdmin from "../views/staff/admin/admin-create-view";
import EditAdmin from "../views/staff/admin/admin-edit-view";
// Parent Views
import Parent from "../views/staff/parent/parent-list-view";
import CreateParent from "../views/staff/parent/parent-create-view";
import EditParent from "../views/staff/parent/parent-edit-view";
// User Views
import User from "../views/staff/user/user-list-view";
import CreateUser from "../views/staff/user/user-create-view";
import EditUser from "../views/staff/user/user-edit-view";
// User Reports Views
import UserReport from "../views/staff/user-report/user-report-list-view";
// ----------------------------------------------------------------------
// Management Views
// Category Views
import Category from "../views/management/category/category-list-view";
import CreateCategory from "../views/management/category/category-create-view";
import EditCategory from "../views/management/category/category-edit-view";
// Challenge Views
import Challenge from "../views/management/challenge/challenge-list-view";
import CreateChallenge from "../views/management/challenge/challenge-create-view";
import EditChallenge from "../views/management/challenge/challenge-edit-view";
// Challenge User Reports Views
import ChallengeUserReport from "../views/management/challenge-user-report/challenge-user-report-list-view";
// Activity Views
import Activity from "../views/management/activity/activity-list-view";
// Badge Views
import Badge from "../views/management/badge/badge-list-view";
import CreateBadge from "../views/management/badge/badge-create-view";
import EditBadge from "../views/management/badge/badge-edit-view";
// Award Views
import Award from "../views/management/award/award-list-view";
import CreateAward from "../views/management/award/award-create-view";
import EditAward from "../views/management/award/award-edit-view";
// Step Views
import Step from "../views/management/step/step-list-view";
import CreateStep from "../views/management/step/step-create-view";
import EditStep from "../views/management/step/step-edit-view";
// Settings Views
import Settings from "../views/management/settings/settings-list-view";

import { paths } from "./paths";

interface AppRoute {
  path: any;
  element: React.ReactElement;
}

export const routes = {
  //Auth Routes
  login: paths.auth.standard.login,
  // ----------------------------------------------------------------------
  // Overview Routes
  // Dashboard Routes
  dashboard: paths.overview.dashboard.root,
  // Challenge Suggestions Routes
  challengeSuggestions: paths.overview.challengeSuggestions.root,
  challengeSuggestionsCreate: paths.overview.challengeSuggestions.create,
  // Story Reports Routes
  storyReports: paths.overview.storyReports.root,
  // Chat Routes
  chat: paths.overview.chat.root,
  // ----------------------------------------------------------------------
  // Staff Routes
  // User Routes
  users: paths.staff.user.root,
  usersCreate: paths.staff.user.create,
  usersEdit: (id: string) => paths.staff.user.edit(id),
  // Parent Routes
  parents: paths.staff.parent.root,
  parentsCreate: paths.staff.parent.create,
  parentsEdit: (id: string) => paths.staff.parent.edit(id),
  // Admin Routes
  admins: paths.staff.admin.root,
  adminsCreate: paths.staff.admin.create,
  adminsEdit: (id: string) => paths.staff.admin.edit(id),
  // User Reports Routes
  userReports: paths.staff.userReports.root,
  // ----------------------------------------------------------------------
  // Management Routes
  // Category Routes
  categories: paths.management.category.root,
  categoriesCreate: paths.management.category.create,
  // 'categoriesEdit': paths.management.category.edit,
  categoriesEdit: (id: string) => paths.management.category.edit(id),
  // Challenge Routes
  challenges: paths.management.challenge.root,
  challengesCreate: paths.management.challenge.create,
  challengesEdit: (id: string) => paths.management.challenge.edit(id),
  // Challenge Reports Routes
  challengeUserReports: paths.management.challengeUserReports.root,
  // Activity Routes
  activities: paths.management.activity.root,
  // Badge Routes
  badges: paths.management.badge.root,
  badgesCreate: paths.management.badge.create,
  badgesEdit: (id: string) => paths.management.badge.edit(id),
  // Award Routes
  awards: paths.management.award.root,
  awardsCreate: paths.management.award.create,
  awardsEdit: (id: string) => paths.management.award.edit(id),
  // Step Routes
  steps: paths.management.step.root,
  stepsCreate: paths.management.step.create,
  stepsEdit: (id: string) => paths.management.step.edit(id),
  // Settings Routes
  //   settings: paths.management.settings.root,
};

export const routesComponents: AppRoute[] = [
  // Overview Routes to Components
  // Dashboard Routes to Components
  { path: routes.dashboard, element: <Dashboard /> },
  // Challenge Suggestions Routes to Components
  { path: routes.challengeSuggestions, element: <ChallengeSuggestion /> },
  {
    path: routes.challengeSuggestionsCreate,
    element: <CreateChallengeSuggestion />,
  },
  // Story Reports Routes to Components
  { path: routes.storyReports, element: <StoryReport /> },
  // Chat Routes to Components
  { path: routes.chat, element: <Chat /> },
  // ----------------------------------------------------------------------
  // Staff Routes to Components
  // User Routes to Components
  { path: routes.users, element: <User /> },
  { path: routes.usersCreate, element: <CreateUser /> },
  { path: routes.usersEdit(":id"), element: <EditUser /> },
  // Parent Routes to Components
  { path: routes.parents, element: <Parent /> },
  { path: routes.parentsCreate, element: <CreateParent /> },
  { path: routes.parentsEdit(":id"), element: <EditParent /> },
  // Admin Routes to Components
  { path: routes.admins, element: <Admin /> },
  { path: routes.adminsCreate, element: <CreateAdmin /> },
  { path: routes.adminsEdit(":id"), element: <EditAdmin /> },
  // User Reports Routes to Components
  { path: routes.userReports, element: <UserReport /> },
  // ----------------------------------------------------------------------
  // Management Routes to Components
  // Category Routes to Components
  { path: routes.categories, element: <Category /> },
  { path: routes.categoriesCreate, element: <CreateCategory /> },
  { path: routes.categoriesEdit(":id"), element: <EditCategory /> },
  // Challenge Routes to Components
  { path: routes.challenges, element: <Challenge /> },
  { path: routes.challengesCreate, element: <CreateChallenge /> },
  { path: routes.challengesEdit(":id"), element: <EditChallenge /> },
  // Challenge User Reports Routes to Components
  { path: routes.challengeUserReports, element: <ChallengeUserReport /> },
  // Activity Routes to Components
  { path: routes.activities, element: <Activity /> },
  // Badge Routes to Components
  { path: routes.badges, element: <Badge /> },
  { path: routes.badgesCreate, element: <CreateBadge /> },
  { path: routes.badgesEdit(":id"), element: <EditBadge /> },
  // Award Routes to Components
  { path: routes.awards, element: <Award /> },
  { path: routes.awardsCreate, element: <CreateAward /> },
  { path: routes.awardsEdit(":id"), element: <EditAward /> },
  // Step Routes to Components
  { path: routes.steps, element: <Step /> },
  { path: routes.stepsCreate, element: <CreateStep /> },
  { path: routes.stepsEdit(":id"), element: <EditStep /> },
  // Settings Routes to Components
  // {path: routes.settings, element: <Settings />},
];
