// DataTable.tsx
import React from "react";

import { Card, Table, TableBody, TableContainer } from "@mui/material";

import {
  DataTableToolbar,
  DataTableFiltersResult,
  DataTableHead,
  DataTableRow,
  DataTableNoData,
  DataTablePagination,
} from ".";
import { TableSkeleton } from "../../minimal/table";

const TableHeadStyles = {
  // Add backgroundColor for table head
  backgroundColor: "#DFE3E8",
  /* Apply sticky positioning to the table head */
  position: "sticky!important",
  top: 0,
  zIndex: 1,
};

const TablePaginationStyles = {
  // Set backgroundColor for table pagination
  backgroundColor: "#DFE3E8",
};

interface DataTableProps {
  tableName: string;
  deleteTitle: string;
  mainCol: string;
  table: any; // Replace 'any' with the actual type for table state
  tableCols: any[]; // Replace 'any' with the actual type for table head
  tableData: any[]; // Adjust the type according to your data structure
  filters?: any; // Adjust the type according to your data structure
  resetFilters?: boolean;
  denseHeight: number;
  emptyRows?: number;
  notFound: boolean;
  isLoading?: boolean;
  handleFilters: (name: string, value: any) => void;
  handleResetFilters: () => void;
  // handleAddRow:() => void;
  handleEditRow?: (id: string, row: any) => void;
  handleDeleteRow?: (id: string) => void;
  handleViewRow?: (id: string, row: any) => void;
  handleChangeState: (id: string, state: boolean) => void;
  handleApprovalState?: (id: string, approval: string) => void;
  onSelectRow?: (id: string, selectionMode: string) => void;
  // onSelectAllRows ,
  hasSearch?: boolean;
  hasEdit?: boolean;
  hasDelete?: boolean;
  hasView?: boolean;
  hasOptions?: boolean;
  selectionMode?: "single" | "multiple";
  hasSelections?: boolean;
}

const DataTable: React.FC<DataTableProps> = ({
  tableName,
  deleteTitle,
  mainCol,
  table,
  tableCols,
  tableData,
  filters,
  resetFilters,
  denseHeight,
  emptyRows,
  notFound,
  isLoading,
  handleFilters,
  handleResetFilters,
  // handleAddRow,
  handleEditRow,
  handleDeleteRow,
  handleViewRow,
  handleChangeState,
  handleApprovalState,
  onSelectRow,
  hasSearch = true,
  hasEdit = true,
  hasDelete = true,
  hasView = false,
  hasOptions = false,
  selectionMode = "single", // Default to single selection mode
  hasSelections = false,
}) => {
  return (
    <div>
      <Card sx={{ borderRadius: "2rem" }}>
        {/* table toolbar for table name and search text-field and export options */}
        <DataTableToolbar
          tableName={tableName}
          filters={filters}
          onFilters={handleFilters}
          hasSearch={hasSearch}
        />

        {/* reset filters bar */}
        {resetFilters && (
          <DataTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            onResetFilters={handleResetFilters}
            results={tableData.length}
            sx={{ p: 2.5, py: 0 }}
          />
        )}

        {/* table */}
        <TableContainer
          sx={{
            position: "relative",
            overflow: "auto",
            maxHeight: "57vh",
            minHeight: "57vh",
          }}
        >
          <Table size={table.dense ? "small" : "medium"} sx={{ minWidth: 960 }}>
            {/* table head (table columns) */}
            <DataTableHead
              order={table.order}
              orderBy={table.orderBy}
              headLabel={tableCols}
              rowCount={tableData.length}
              numSelected={table.selected.length}
              onSort={table.onSort}
              onSelectAllRows={(checked) =>
                table.onSelectAllRows(
                  checked,
                  tableData.map((row: any) => row.id)
                )
              }
              selectionMode={selectionMode}
              sx={TableHeadStyles}
            />

            {/* table body */}
            <TableBody>
              {isLoading
                ? [...Array(table.rowsPerPage)].map((i, index) => (
                    <TableSkeleton key={index} sx={{ height: 80 }} />
                  ))
                : tableData.length > 0 &&
                  tableData
                    .slice(
                      table.page * table.rowsPerPage,
                      table.page * table.rowsPerPage + table.rowsPerPage
                    )
                    .map((row: any, index: number) => (
                      <DataTableRow
                        key={row.id || index} // Use row.id if available, otherwise use index
                        row={row}
                        selected={table.selected.includes(row.id)}
                        onSelectRow={() => onSelectRow?.(row.id, selectionMode)}
                        onDeleteRow={() => handleDeleteRow?.(row.id)}
                        onEditRow={() => handleEditRow?.(row.id, row)}
                        onViewRow={() => handleViewRow?.(row.id, row)}
                        onListCellClick={(state: boolean) =>
                          handleChangeState(row.id, state)
                        }
                        onListOptionClick={(approval: string) =>
                          handleApprovalState?.(row.id, approval)
                        }
                        columns={tableCols}
                        deleteTitle={deleteTitle}
                        mainCol={mainCol}
                        selectionMode={selectionMode}
                        hasSelections={hasSelections}
                        hasEdit={hasEdit}
                        hasDelete={hasDelete}
                        hasView={hasView}
                        hasOptions={hasOptions}
                      />
                    ))}

              {/* Empty Rows if data is less than the number of rows per page */}
              {/* <DataTableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows} /> */}

              {/* when table-list (tableData) is empty >> no data found */}
              <DataTableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </TableContainer>

        <DataTablePagination
          count={tableData.length}
          page={table.page}
          rowsPerPage={table.rowsPerPage}
          onPageChange={table.onChangePage}
          onRowsPerPageChange={table.onChangeRowsPerPage}
          dense={table.dense}
          onChangeDense={table.onChangeDense}
          sx={TablePaginationStyles}
        />
      </Card>
      {/* </Container> */}
    </div>
  );
};

export default DataTable;
