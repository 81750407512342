import React, { useMemo, useCallback, useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";

import { useForm } from "react-hook-form";

import axios from "axios";

import {
  Box,
  Card,
  Grid,
  Stack,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";

import LoadingButton from "@mui/lab/LoadingButton";

import FormProvider, {
  RHFRoundedTextField,
  RHFSwitch,
  RHFUploadAvatar,
} from "../../../../components/minimal/hook-form";
import Iconify from "../../../../components/minimal/iconify";
import { useSnackbar } from "../../../../components/minimal/snackbar";

import { IParentItem } from "../../../../types/parent";
import { fData } from "../../../../utils/format-number";

import { useBoolean } from "../../../../hooks/use-boolean";

import { ParentSchema } from "../parent-schema";
import { HOST_API } from "../../../../config-global";
import { handleErrors } from "../../../../handlers/axiosErrorsHandler";

import { useAuth } from "../../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import { getStorage } from "../../../../hooks/use-local-storage";

// ----------------------------------------------------------------------

interface ParentFormProps {
  onClose: VoidFunction;
  currentUser?: IParentItem;
}

// ----------------------------------------------------------------------

const ParentForm: React.FC<ParentFormProps> = ({ currentUser, onClose }) => {
  const { setAuthenticated } = useAuth();

  const password = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const token = getStorage("Token");

  const { id } = useParams(); // Extract categoryId from the URL
  const isUpdate = id !== undefined; // Check if it's an update

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const response = await axios.get(
          `${HOST_API}/api/admin/users/parents/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-locale": "en",
            },
          }
        );
        const categoryData = response?.data?.data; // Assuming the API response contains the category data
        reset({ ...categoryData });
      } catch (error) {
        // Handle error, e.g., display an error message
        // console.error('Error fetching category data:', error);
        handleErrors(error, setAuthenticated, enqueueSnackbar);
      }
    };

    if (isUpdate) {
      // Update State
      fetchCategoryData();
    } else {
    }
  }, [id, token, isUpdate, setAuthenticated, enqueueSnackbar]);

  //must default values attributes is equal to categorySchema
  const defaultValues = useMemo(
    () => ({
      name: currentUser?.name || "",
      city: currentUser?.city || "",
      role: currentUser?.role || "",
      email: currentUser?.email || "",
      password: currentUser?.password || "",
      state: currentUser?.state || "",
      status: currentUser?.status || "",
      address: currentUser?.address || "",
      country: currentUser?.country || "",
      zipCode: currentUser?.zipCode || "",
      company: currentUser?.company || "",
      avatarUrl: currentUser?.avatarUrl || null,
      phoneNumber: currentUser?.phoneNumber || "",
      isVerified: currentUser?.isVerified || true,
    }),
    [isUpdate]
  );

  const methods = useForm({
    resolver: yupResolver(ParentSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await axios
        .post(
          `${HOST_API}/api/admin/auth/login`,
          { name: data.name, phoneNumber: data.phoneNumber, email: data.email },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          enqueueSnackbar(isUpdate ? "Update success!" : "Create success!");
        });
    } catch (error: any) {
      handleErrors(error, setAuthenticated, enqueueSnackbar);
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue("avatarUrl", newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ pt: 5, pb: 5, px: 3, minHeight: "26rem" }}>
            {/* Rounded Upload File for Avatar/User Profile*/}
            {/* helperText is a text displayed under upload file to tell the user what to upload */}
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="avatarUrl"
                maxSize={3145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 3,
                      mx: "auto",
                      display: "block",
                      textAlign: "center",
                      color: "text.disabled",
                    }}
                  >
                    Allowed *.jpeg, *.jpg, *.png, *.gif
                    <br /> max size of {fData(3145728)}
                  </Typography>
                }
              />
            </Box>

            <RHFSwitch
              name="isVerified"
              labelPlacement="start"
              label={
                <>
                  <Typography variant="subtitle2" sx={{ mb: 0.5 }}>
                    Email Verified
                  </Typography>
                  <Typography variant="body2" sx={{ color: "text.secondary" }}>
                    Disabling this will automatically send the user a
                    verification email
                  </Typography>
                </>
              }
              sx={{ mx: 0, width: 1, justifyContent: "space-between" }}
            />

            {currentUser && (
              <Stack justifyContent="center" alignItems="center" sx={{ mt: 3 }}>
                <Button variant="soft" color="error">
                  Delete User
                </Button>
              </Stack>
            )}
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, minHeight: "26rem" }}>
            <Box
              rowGap={3} // gap between form rows
              columnGap={2} // gap between form columns
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
            >
              <RHFRoundedTextField
                name="name"
                label="Full Name"
                autoComplete="new-password"
              />
              <RHFRoundedTextField name="phoneNumber" label="Phone Number" />
              <RHFRoundedTextField
                name="email"
                label="Email Address"
                autoComplete="new-password"
              />
              <RHFRoundedTextField
                name="password"
                label="Password"
                type={password.value ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={password.onToggle} edge="end">
                        <Iconify
                          icon={
                            password.value
                              ? "solar:eye-broken"
                              : "solar:eye-closed-bold"
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <RHFRoundedTextField name="state" label="State/Region" />
              <RHFRoundedTextField name="city" label="City" />
              <RHFRoundedTextField
                name="address"
                label="Address"
                autoComplete="new-password"
              />
              <RHFRoundedTextField name="zipCode" label="Zip/Code" />
              <RHFRoundedTextField
                name="company"
                label="Company"
                autoComplete="new-password"
              />
              <RHFRoundedTextField name="role" label="Role" />
            </Box>

            {/* Form Actions Bar */}
            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                loading={isSubmitting}
                style={{ borderRadius: 20, textTransform: "capitalize" }}
                className="bg-gradient-to-br from-red-400 to-orange-400 capitalize"
              >
                {isUpdate ? "Update Parent" : "Create Parent"}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export default ParentForm;
