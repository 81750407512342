import React, { useCallback, useEffect, useState } from "react";

import isEqual from "lodash/isEqual";

import CustomBreadcrumbs from "../../../components/minimal/custom-breadcrumbs";
import {
  useTable,
  emptyRows,
  getComparator,
} from "../../../components/minimal/table";

import {
  IChallengeTableFilters,
  IChallengeTableFilterValue,
} from "../../../types/challenge";
import { CHALLENGE_SUGGESTION_COLUMNS } from "../../../consts/challenge-suggestions";

import DataTable from "../../../components/common/data-table/data-table";

import { applyFilter } from "../../../utils/data-table-filter";
import { routes } from "../../../routes/routes";
import axios from "axios";
import { HOST_API } from "../../../config-global";
import { getStorage } from "../../../hooks/use-local-storage";
import { useAuth } from "../../../hooks/useAuth";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

interface ChallengeSuggestionProps {}

// ----------------------------------------------------------------------

const defaultFilters: IChallengeTableFilters = {
  title: "",
};

// ----------------------------------------------------------------------

const ChallengeSuggestion: React.FC<ChallengeSuggestionProps> = () => {
  const table = useTable();
  const { setAuthenticated } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const [tableData, setTableData] = useState([]);

  const [filters, setFilters] = useState(defaultFilters);
  const token = getStorage("Token");

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        await axios
          .get(`${HOST_API}/api/admin/challenges?user_id[isNull]=true`, {
            headers: {
              Authorization: `Bearer ${token}`,
              "x-locale": "en",
            },
          })
          .then((res) => {
            var response = res.data;
            setTableData(response.data.items);
          });
      } catch (error: any) {
        // handleErrors(error, setAuthenticated, enqueueSnackbar);
      }
    };

    fetchData();
  }, [token, setAuthenticated, enqueueSnackbar]);
  const denseHeight = table.dense ? 52 : 72;

  const resetFilters = !isEqual(defaultFilters, filters);

  const notFound =
    (!dataFiltered.length && resetFilters) || !dataFiltered.length;

  const handleFilters = useCallback(
    (name: string, value: IChallengeTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleEditRow = useCallback((id: string) => {
    // navigate(routes.categoriesCreate);
  }, []);

  const handleDeleteRow = useCallback(
    (id: string) => {
      const deleteRow = tableData.filter((row: any) => row.id !== id);
      setTableData(deleteRow);

      table.onUpdatePageDeleteRow(dataInPage.length);
    },
    [dataInPage.length, table, tableData]
  );

  const handleChangeState = useCallback((id: string) => {
    return null;
  }, []);

  // State to hold selected rows
  const [selectedRows, setSelectedRows] = useState<any>([]);

  // Function to handle row selection
  const handleSelectRow = useCallback(
    (id: string, selectionMode: string) => {
      // Check if the row is already selected
      const selectedIndex = selectedRows.indexOf(id);
      let newSelected: any = [];

      table.onSelectRow(id);
      if (selectedIndex === -1) {
        // If not selected, add to the selection
        newSelected = newSelected.concat(selectedRows, id);
      } else if (selectedIndex === 0) {
        // If first element is selected, remove it
        newSelected = newSelected.concat(selectedRows.slice(1));
      } else if (selectedIndex === selectedRows.length - 1) {
        // If last element is selected, remove it
        newSelected = newSelected.concat(selectedRows.slice(0, -1));
      } else if (selectedIndex > 0) {
        // If middle element is selected, remove it
        newSelected = newSelected.concat(
          selectedRows.slice(0, selectedIndex),
          selectedRows.slice(selectedIndex + 1)
        );
      }

      // Update the selected rows state
      setSelectedRows(newSelected);
    },
    [table, selectedRows]
  );

  return (
    <div>
      {/* Bread Crumb + Create Button */}
      <div className="flex items-center justify-between pb-3 h-12">
        <CustomBreadcrumbs
          links={[
            { name: "Overview", href: routes.challengeSuggestions },
            {
              name: "Challenge Suggestions",
              href: routes.challengeSuggestions,
            },
          ]}
        />
        {/* <RoundedButton label='New Challenge' backgroundColor='#637381' onClick={handleAddRow} icon='mingcute:add-line' /> */}
      </div>

      {/* DataTable */}
      <DataTable
        tableName="Challenge Suggestions"
        deleteTitle="Challenge Suggestion"
        mainCol="title"
        table={table}
        tableCols={CHALLENGE_SUGGESTION_COLUMNS}
        tableData={dataFiltered}
        filters={filters}
        resetFilters={resetFilters}
        denseHeight={denseHeight}
        emptyRows={emptyRows(table.page, table.rowsPerPage, tableData.length)}
        notFound={notFound}
        handleFilters={handleFilters}
        handleResetFilters={handleResetFilters}
        handleEditRow={handleEditRow}
        handleDeleteRow={handleDeleteRow}
        handleChangeState={handleChangeState}
        onSelectRow={handleSelectRow}
      />
    </div>
  );
};

export default ChallengeSuggestion;
